import React from "react"
import Layout from "../components/layout"
import PropTypes from "prop-types"
import Heading from "@components/elements/heading"
import Paragraph from "@components/elements/paragraph"
import Flex from "@components/elements/flex"
import Grid from "@components/elements/grid"
import Anchor from "@components/elements/anchor"
import Div from "@components/elements/div"
import { graphql } from "gatsby"
import Image from "@components/image"
import { processImages } from "@utils/process-images"
import { LogoCarousel } from "@components/common/logo-carousel"
import { YoutubeEmbed } from "@components/common/youtube-embed"
import Button from "@components/elements/button"
import { useTheme } from "styled-components"

const DashboardsPage = ({ data }) => {
  const images = processImages(data.images.edges)
  const { color } = useTheme()

  return (
    <Layout
      title={`Best Custom Dashboard Software & Reporting Tool`}
      description={`Analyze your key metrics. Monitor your performance. Get all your data in one place with our interactive dashboard software. ✔ Get a free trial today!`}
      fullWidth
      marginless
      hideOverflow
    >
      <Flex width="100%" container gap="8rem" margin="10rem auto 8rem">
        <Flex
          maxWidth="100%"
          textContainer
          gap="2rem"
          center
          alignItems="center"
        >
          <Heading as="h1">
            Up your game with world-class dynamic software for online dashboards
          </Heading>
          <Div maxWidth="100%" position="relative" overflow="hidden">
            <LogoCarousel padding="2rem 0" dark />
          </Div>
          <Button.PowerMetrics
            noticeColor={color.indigo600}
            data-cta-location="header"
          >
            Get Started Free
          </Button.PowerMetrics>
        </Flex>
        <Flex textContainer gap="2rem" center alignItems="center">
          <Heading as="h2">
            With all of your key metrics in one place, monitoring your
            company&apos;s performance has never been easier
          </Heading>
          <Paragraph>
            Data is the only constant when it comes to business, which makes it
            even more difficult to stay on top of your game. Now more than ever
            you need to be in full control of your company&apos;s data or risk
            falling behind.
          </Paragraph>
        </Flex>
        <Div
          container
          background="#edf3fd"
          width="100%"
          borderRadius="1rem"
          padding="4rem"
          center
        >
          <Heading as="h3" margin="0 0 2rem">
            Build world-class dashboards using Klipfolio PowerMetrics
          </Heading>
          <YoutubeEmbed style={{ borderRadius: "1rem" }}>
            <iframe
              src="https://www.youtube.com/embed/cuqFxetRSls"
              frameBorder="0"
              allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
            ></iframe>
          </YoutubeEmbed>
        </Div>
        <Div>
          <Div maxWidth="700px" center margin="0 auto 4rem">
            <Heading as="h3" margin="0 0 2rem">
              Klipfolio puts you in the driver&apos;s seat
            </Heading>
            <Paragraph>
              Whether you manage a few client accounts, or hundreds,
              Klipfolio&apos;s Client Management platform will scale with your
              business.
            </Paragraph>
          </Div>
          <Grid columns="repeat(3, 1fr)" columnsMd="1fr" gap="2rem">
            <Flex gap="1rem">
              <Div
                width="min-content"
                borderRadius="100%"
                background="#f3f5fc"
                padding="1rem"
              >
                <Image
                  height={40}
                  width={40}
                  file={images["410bfdbf-692c-4ca3-97f8-bd049be119b9"]}
                />
              </Div>
              <Paragraph fontWeight="700">Hundreds of integrations</Paragraph>
              <Paragraph fontSize="1.1rem" lineHeight={1.8}>
                With&nbsp;
                <Anchor link="https://www.klipfolio.com/integrations">
                  App Integrations and Mashups
                </Anchor>
                , Klipfolio can pull your company&apos;s data from anywhere.
                From Marketing to Finance, Klipfolio allows for real time
                business decision making.
              </Paragraph>
            </Flex>
            <Flex gap="1rem">
              <Div
                width="min-content"
                borderRadius="100%"
                background="#f3f5fc"
                padding="1rem"
              >
                <Image
                  height={40}
                  width={40}
                  file={images["c3c6caf7-dc65-4b09-826b-40d07109a204"]}
                />
              </Div>
              <Paragraph fontWeight="700">Your data when you need it</Paragraph>
              <Paragraph fontSize="1.1rem" lineHeight={1.8}>
                Klipfolio saves you time by eliminating the need for ad-hoc
                reporting through collaboration and organizational transparency.
                Your business is real time, so you should be too! Simple as
                that.
              </Paragraph>
            </Flex>
            <Flex gap="1rem">
              <Div
                width="min-content"
                borderRadius="100%"
                background="#f3f5fc"
                padding="1rem"
              >
                <Image
                  height={40}
                  width={40}
                  file={images["157b4bd9-e59b-41f8-a4c0-c41cc3df99c4"]}
                />
              </Div>
              <Paragraph fontWeight="700">Make Klipfolio your own</Paragraph>
              <Paragraph fontSize="1.1rem" lineHeight={1.8}>
                Personalize your dashboard software with the use of custom
                visualizations. Take customization to a new level by creating
                unique themes with CSS features and Javascript file uploads
                within our application.
              </Paragraph>
            </Flex>
          </Grid>
        </Div>
        <Div>
          <Flex gap="2rem" textContainer margin="8rem auto">
            <Heading as="h2">
              Searching for an easy to use dashboard software solution designed
              for how you work? You&apos;ve come to the right place.
            </Heading>
            <Paragraph>
              We know that every role has different data points to monitor,
              requires different connectors, and has different value props.
              That&apos;s why we&apos;ve built the best dashboard software tool
              for you regardless of your role, industry, service and data
              sources. Beginner to advanced, there are no technical skills
              required. From a quick overview of where your business stands to a
              deep dive analysis, unleash the power of your data.
            </Paragraph>
            <Paragraph>
              With everything from dashboard examples and commonly used metrics
              to the various ways you can easily select and share your
              dashboards, our dashboard software tool will help you deliver the
              data insights you have always wanted.
            </Paragraph>
            <Heading as="h3">
              Here are three common data analytics dashboards by role:
            </Heading>
          </Flex>
          <Grid columns="repeat(3, 1fr)" columnsSm="1fr" gap="2rem">
            <Flex gap="2rem" alignItems="flex-start">
              <Heading as="h2" fontSize="2rem" fontWeight="700">
                Sales dashboard software
              </Heading>
              <Paragraph fontSize="1.1rem" lineHeight="1.8">
                See how sales dashboard software can propel your team, your
                business, and yourself to success. Choose a sales leaderboard to
                create some friendly competition around the office, or monitor
                the inventory of your products.
              </Paragraph>
              <Button
                href="https://www.klipfolio.com/resources/dashboard-examples/sales"
                arrow
                background="#EDF3FD"
                color="#4B57C5"
                notice={false}
              >
                Learn More
              </Button>
            </Flex>
            <Flex gap="2rem" alignItems="flex-start">
              <Heading as="h2" fontSize="2rem" fontWeight="700">
                Marketing dashboard software
              </Heading>
              <Paragraph fontSize="1.1rem" lineHeight="1.8">
                Ensure you and your team have a clear view into your most
                important marketing metrics - be it ROI on a video campaign,
                traffic on your website, social media news, current performance
                of an A/B test, or email open rates all in one dashboard
                software reporting tool.
              </Paragraph>
              <Button
                href="https://www.klipfolio.com/resources/dashboard-examples/marketing"
                arrow
                background="#EDF3FD"
                color="#4B57C5"
                notice={false}
              >
                Learn More
              </Button>
            </Flex>
            <Flex gap="2rem" alignItems="flex-start">
              <Heading as="h2" fontSize="2rem" fontWeight="700">
                Executive dashboard software
              </Heading>
              <Paragraph fontSize="1.1rem" lineHeight="1.8">
                As a high-level executive, you likely have a million things
                flying at you from all sides. A comprehensive management
                dashboard report can help get everyone in the company on the
                same page.
              </Paragraph>
              <Button
                href="https://www.klipfolio.com/resources/dashboard-examples/executive"
                arrow
                background="#EDF3FD"
                color="#4B57C5"
                notice={false}
              >
                Learn More
              </Button>
            </Flex>
          </Grid>
          <Div textContainer margin="8rem auto 0">
            <Paragraph margin="0 0 1rem">
              Check out our&nbsp;
              <Anchor link="https://www.klipfolio.com/department-solutions">
                dashboard reporting tools section by role
              </Anchor>
              .
            </Paragraph>
            <Paragraph fontSize="1.1rem">
              Here you will find more examples including SaaS, Data Analysts,
              Accounting/Finance, Customer Support, Digital Advertising, Social
              Media, User Experience, Human Resources, Operations and Product
              Management just to name a few.
            </Paragraph>
          </Div>
        </Div>
      </Flex>
      <Flex width="100%" padding="4rem 0" background="#f3f5fc">
        <Grid container columns="1fr 2fr" columnsMd="1fr" gap="4rem">
          <Div>
            <Heading as="h2" margin="0 0 2rem">
              Share your dashboard software insights
            </Heading>
            <Paragraph fontSize="1.1rem" lineHeight="1.8">
              Our experience working with 11,000 customers has taught us that
              there are countless ways to share data. So we&apos;ve made sure
              that sharing is easy - with anyone you want, whenever you want.
              This means sharing dashboards and Klips within your organization,
              with your clients, and publicly, as a download, pre-scheduled or
              in real-time, on various devices.
            </Paragraph>
          </Div>
          <Grid columns="1fr 1fr" columnsMd="1fr" gap="4rem">
            <Div>
              <Div
                borderRadius="100%"
                background="white"
                padding="1rem"
                margin="0 0 1rem"
                width="min-content"
              >
                <Image
                  height={40}
                  width={40}
                  file={images["5f4793c3-963c-4b0e-a1f1-7f63ab424463"]}
                />
              </Div>
              <Heading as="h3" fontSize="1.8rem" margin="0 0 1rem">
                TV Dashboards
              </Heading>
              <Paragraph fontSize="1.1rem" lineHeight="1.8">
                Displaying multiple&nbsp;
                <Anchor link="/blog/what-is-a-tv-dashboard">
                  TV dashboards
                </Anchor>
                , data boards, or monitors in your office ensures your team
                remains data-driven and never loses sight of company goals.
              </Paragraph>
            </Div>
            <Div>
              <Div
                borderRadius="100%"
                background="white"
                padding="1rem"
                margin="0 0 1rem"
                width="min-content"
              >
                <Image
                  height={40}
                  width={40}
                  file={images["b59c1dcc-e37c-4388-a124-19a2d48af21d"]}
                />
              </Div>
              <Heading as="h3" fontSize="1.8rem" margin="0 0 1rem">
                Mobile Business Dashboard
              </Heading>
              <Paragraph fontSize="1.1rem" lineHeight="1.8">
                View your dashboards on the go by downloading our mobile device
                app. Enjoy a streamlined dashboard software experience on your
                mobile phone, with Klipfolio for iPhone and Android.
              </Paragraph>
            </Div>
            <Div>
              <Div
                borderRadius="100%"
                background="white"
                padding="1rem"
                margin="0 0 1rem"
                width="min-content"
              >
                <Image
                  height={40}
                  width={40}
                  file={images["772dfd65-1a07-474b-89c5-53ce2ab7b34e"]}
                />
              </Div>
              <Heading as="h3" fontSize="1.8rem" margin="0 0 1rem">
                Online Web Dashboard
              </Heading>
              <Paragraph fontSize="1.1rem" lineHeight="1.8">
                Share your dashboards via the web using published links, which
                will provide people with the link access to your dashboards,
                publicly via search engines, or password protected, even if they
                are not users.
              </Paragraph>
            </Div>
            <Div>
              <Div
                borderRadius="100%"
                background="white"
                padding="1rem"
                margin="0 0 1rem"
                width="min-content"
              >
                <Image
                  height={40}
                  width={40}
                  file={images["b5915b01-658c-40a9-8214-dcca8cac2757"]}
                />
              </Div>
              <Heading as="h3" fontSize="1.8rem" margin="0 0 1rem">
                Email Dashboards
              </Heading>
              <Paragraph fontSize="1.1rem" lineHeight="1.8">
                Klipfolio offers your the ability to turn key data
                visualizations and dashboards into regularly scheduled email
                reports. This is perfect for daily and bi-weekly updates as well
                as tracking goals.
              </Paragraph>
            </Div>
            <Div>
              <Div
                borderRadius="100%"
                background="white"
                padding="1rem"
                margin="0 0 1rem"
                width="min-content"
              >
                <Image
                  height={40}
                  width={40}
                  file={images["b2a8969a-6512-4e74-8e50-f00771094b4a"]}
                />
              </Div>
              <Heading as="h3" fontSize="1.8rem" margin="0 0 1rem">
                PDF Dashboard Reports
              </Heading>
              <Paragraph fontSize="1.1rem" lineHeight="1.8">
                Visual downloads in a single click, yes please. Generate a
                variety of downloadable reports based on data visualizations, or
                entire dashboards. This means you can provide your performance
                metrics in presentations, charts, reports, and communications.
              </Paragraph>
            </Div>
            <Div>
              <Div
                borderRadius="100%"
                background="white"
                padding="1rem"
                margin="0 0 1rem"
                width="min-content"
              >
                <Image
                  height={40}
                  width={40}
                  file={images["c73891f4-90cb-4584-a3b1-3812a3de7742"]}
                />
              </Div>
              <Heading as="h3" fontSize="1.8rem" margin="0 0 1rem">
                KPI Dashboard
              </Heading>
              <Paragraph fontSize="1.1rem" lineHeight="1.8">
                To help keep teams and organizations on the same page, a&nbsp;
                <Anchor link="/resources/articles/what-is-a-key-performance-indicator">
                  KPI dashboard
                </Anchor>
                &nbsp;helps provide at-a-glance insights into relevant key
                performance indicators to a particular objective or key buiness
                process, helping to easily visualize how the performance of key
                measures are being met.
              </Paragraph>
            </Div>
          </Grid>
        </Grid>
      </Flex>
      <Flex width="100%" padding="4rem 0">
        <Grid container columns="1fr 2fr" columnsMd="1fr" gap="4rem">
          <Div>
            <Heading as="h2" margin="0 0 2rem">
              Connect with your everyday data sources all in one place, in
              seconds
            </Heading>
            <Paragraph fontSize="1.1rem" lineHeight="1.8">
              Still don&apos;t have you convinced? Here are some of the ways our
              customers create Klipfolio dashboards to monitor their
              performance. Need some creative inspiration before building your
              dashboards? Visit our&nbsp;
              <Anchor link="https://www.klipfolio.com/gallery/dashboards">
                Pre-Built Dashboard Gallery
              </Anchor>
              .
            </Paragraph>
          </Div>
          <Grid columns="1fr 1fr" columnsMd="1fr" gap="4rem" margin="0 0 6rem">
            <Div>
              <Div
                borderRadius="100%"
                background="#f3f5fc"
                padding="1rem"
                margin="0 0 1rem"
                width="min-content"
              >
                <Image
                  height={40}
                  width={40}
                  file={images["148ff414-da6a-4a3f-9b1e-4ddd1aa2667b"]}
                />
              </Div>
              <Heading as="h3" fontSize="1.8rem" margin="0 0 1rem">
                Excel
              </Heading>
              <Paragraph fontSize="1.1rem" lineHeight="1.8">
                Build custom dashboard reports using your Excel spreadsheets and
                combine them with metrics from web services, SQL databases, and
                custom data sources.
              </Paragraph>
            </Div>
            <Div>
              <Div
                borderRadius="100%"
                background="#f3f5fc"
                padding="1rem"
                margin="0 0 1rem"
                width="min-content"
              >
                <Image
                  height={40}
                  width={40}
                  file={images["0396bb66-1ae5-4926-8a7b-bce1dfe246ac"]}
                />
              </Div>
              <Heading as="h3" fontSize="1.8rem" margin="0 0 1rem">
                Google Sheets
              </Heading>
              <Paragraph fontSize="1.1rem" lineHeight="1.8">
                Develop custom dashboards using any Google Sheet from your
                Google Drive account. Like always, you can combine your Google
                Sheets data set with other services to enhance your dashboard
                experience.
              </Paragraph>
            </Div>
            <Div>
              <Div
                borderRadius="100%"
                background="#f3f5fc"
                padding="1rem"
                margin="0 0 1rem"
                width="min-content"
              >
                <Image
                  height={40}
                  width={40}
                  file={images["b351703c-822b-4f14-89dd-1d3fe6215e03"]}
                />
              </Div>
              <Heading as="h3" fontSize="1.8rem" margin="0 0 1rem">
                Google Analytics
              </Heading>
              <Paragraph fontSize="1.1rem" lineHeight="1.8">
                From organic traffic to referrals, to goal conversion, track
                Google Analytics with other services like Facebook Ads,
                Salesforce, and HubSpot to take your dashboarding to the next
                level.
              </Paragraph>
            </Div>
            <Div>
              <Div
                borderRadius="100%"
                background="#f3f5fc"
                padding="1rem"
                margin="0 0 1rem"
                width="min-content"
              >
                <Image
                  height={40}
                  width={40}
                  file={images["be9f4762-0c67-4ed3-94e9-68f4661e1905"]}
                />
              </Div>
              <Heading as="h3" fontSize="1.8rem" margin="0 0 1rem">
                SQL Database
              </Heading>
              <Paragraph fontSize="1.1rem" lineHeight="1.8">
                Tired of running the same queries day in and day out? With
                Klipfolio, you can use those queries to build powerful
                dashboards that update automatically for you.
              </Paragraph>
            </Div>
          </Grid>
        </Grid>
      </Flex>
    </Layout>
  )
}

DashboardsPage.propTypes = {
  data: PropTypes.object.isRequired,
}

export default DashboardsPage

export const pageQuery = graphql`
  query DashboardsPageQuery {
    images: allDirectusFiles(
      filter: {
        directus_id: {
          in: [
            "410bfdbf-692c-4ca3-97f8-bd049be119b9"
            "c3c6caf7-dc65-4b09-826b-40d07109a204"
            "157b4bd9-e59b-41f8-a4c0-c41cc3df99c4"
            "5f4793c3-963c-4b0e-a1f1-7f63ab424463"
            "b59c1dcc-e37c-4388-a124-19a2d48af21d"
            "772dfd65-1a07-474b-89c5-53ce2ab7b34e"
            "b5915b01-658c-40a9-8214-dcca8cac2757"
            "b2a8969a-6512-4e74-8e50-f00771094b4a"
            "c73891f4-90cb-4584-a3b1-3812a3de7742"
            "148ff414-da6a-4a3f-9b1e-4ddd1aa2667b"
            "0396bb66-1ae5-4926-8a7b-bce1dfe246ac"
            "b351703c-822b-4f14-89dd-1d3fe6215e03"
            "be9f4762-0c67-4ed3-94e9-68f4661e1905"
          ]
        }
      }
    ) {
      edges {
        node {
          directus_id
          cdn
          id
          placeholder
          title
        }
      }
    }
  }
`
